
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Relation from "@/components/reusable/Relation.vue";
import Bank from "@/components/reusable/Bank.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "edit-beneficiary-information",
  components: {
    ErrorMessage,
    Field,
    Form,
    Relation,
    Bank
  },
  props: ["publicId"],
  data() {
    return {
      beneficiaryDetails: {
        accountName: "",
        accountNumber: "",
        bank: "",
        bvn: "",
        relation: "",
        password: "",
        publicId: ""
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    // const submitButton2 = ref<HTMLElement | null>(null);

    const beneficiaryDetailsValidator = Yup.object().shape({
      accountName: Yup.string().required().label("Account name"),
      accountNumber: Yup.number().required().min(10).label("Account number"),
      bank: Yup.mixed().required().label("Bank"),
      bvn: Yup.number().required().min(11).label("Bank verification number"),
      relation: Yup.string().required().label("Relation")
      // password: Yup.string()
      //   .min(8)
      //   .required()
      //   .label("Password"),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Beneficiary Information", [
        "Profile",
        "Beneficiary Information"
      ]);
    });

    return {
      submitButton1,
      beneficiaryDetailsValidator
    };
  },
  mounted() {
    // Get user beneficiary information on page load
    this.getBeneficiaryInformation();
  },
  created() {
    //Set page title
    document.title =
      "Edit Beneficiary Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    getBeneficiaryInformation() {
      //Fetch individual client profile beneficiary infomation
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.PROFILE_BENEFICIARY_INFORMATION, this.publicId)
          .then(({ data }) => {
            //Assign data to form fields
            // this.beneficiaryDetails = data.data;
            this.beneficiaryDetails.accountName = data.data.accountName;
            this.beneficiaryDetails.accountNumber = data.data.accountNumber;
            this.beneficiaryDetails.bank = data.data.bank.publicId;
            this.beneficiaryDetails.bvn = data.data.bvn;
            this.beneficiaryDetails.relation = data.data.relation.publicId;
            this.beneficiaryDetails.publicId = data.data.publicId;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    updateBeneficiaryInformation(values) {
      const submitButton1 = document.getElementById(
        "kt_account_profile_details_submit"
      ) as HTMLInputElement;

      if (submitButton1) {
        //Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.removeAttribute("data-kt-indicator");

          // Send beneficiary information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(
              variables.PROFILE_BENEFICIARY_INFORMATION + "/" + this.publicId,
              values
            )
              .then((response) => {
                variables.toastAlert(response.data.message, "success");
                this.$router.push({ name: "profile" });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    }
  }
});
